*{
  margin: 0em;
  font-family: 'Barlow', sans-serif;
}


.App{
  display: flex;
  flex-direction: column;
}

.header{
  color: #DB4B6C;
  background-color: #fdeef2;
  text-align: left;
  padding: 1em 1em 2em 1em;
  font-size: 3em;
  font-weight: 700;
}

.intro-video{
  background-color: #fdeef2;
}

.profile{
  background-color: #fdeef2;
}

.profile .image1{
  position: absolute;
  z-index: 2;
  margin: 0em 0em auto 10em;
}

.profile .image2{
  position: relative;
  z-index: 1;
  margin: 5em auto 0em 2em;
}

.search{
  background-color: #FFBDCD;
}

.search .image1{
  position: absolute;
  z-index: 2;
  transform: rotate(8deg);
  margin: 5em 0em 0em 8rem;
}
.search .image2{
  position: relative;
  z-index: 1;
  transform: rotate(8deg);
  margin: 0em 2em 5em 4rem;
}

.e-commerce{
  background-color: #ff95ae;
}

.e-commerce .image1{
  position: absolute;
  z-index: 2;
  transform: rotate(-8deg);
  margin: 0em 0em auto 10em;
}
.e-commerce .image2{
  position: relative;
  z-index: 1;
  transform: rotate(-8deg);
  margin: 5em auto 0em 2em;
}

.share{
  background-color: #DB4B6C;
}

.intro-video, .profile, .e-commerce, .search, .share{
  display: flex;
  flex-direction: row;
  height: 40em;
  margin: 0;
}

.email{
  background-color: #505976;
  display: flex;
  flex-direction: column;
  height: 20em;
  margin: 0;
  align-items: center;
  padding: 2em;
}

.ImageSection{
  display: flex;
  flex-direction: row;
  flex: 6 6 0;
  margin: 5rem 3rem;
  position: relative;
}

.share-image{
  display: flex;
  flex-direction: row;
  flex: 6 6 0;
  margin: 5rem 3rem;
}

.share-image1{
  transform: rotate(8deg);
  margin: 0rem 5rem auto auto;
  height: 80%;
  width: auto;
  border-radius: 2em;
  border: black solid 2px;
}

.roadmap{
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ContentSection{
  flex: 6 6 0;
  margin: 5rem 3rem;
  text-align: left;
  font-size: 1.75em;
}
.ContentSection h3{
  margin-bottom: 1em;
}

.intro-content{
  flex: 4 4 0;
  margin: 3rem 0rem 2rem 5rem;
  text-align: left;
  font-size: 1.75em;
}
.intro-content .title{
  margin-bottom: 1em;
}

.video{
  flex: 6 6 0;
  margin: 3rem 1rem;
}

video{
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
}


.image1, .image2{
  height: 80%;
  width: auto;
  border-radius: 2em;
  border: black solid 2px;
}


.email h1{
  margin: 1em;
  font-size: 2.5em;
  color: #FFFFFF;
}

.email p{
  margin: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
  color: #FFFFFF;
}

.email .email-input{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 1em 2em;
}

.email-input input[type="email"] {
  border: none;
  border: 3px solid #DB4B6C;
  height: 3em;
  width: 40em;
  border-radius: 1em;
  padding-left: 1.5em;
  
}

.email-input input:focus {
  outline: none !important;
}

input:focus::placeholder {
  color: transparent;
}

.email-input button[type="submit"]{
  margin: 0em 0.5em;
  height: 3em;
  width: 9em;
  background-color: #DB4B6C;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.05em;
  border: none;
  border-radius: 1em;
}

.email-input button:hover{
  cursor: pointer;
  opacity: 0.8;
}

.email-input button:disabled{
  cursor: wait;
  opacity: 0.4;
}

.empty{
  height: 50%;
  width: 0.6rem;
}

.roadmap-line {
  height: 100%;
  width: 0.6rem;
  background-color: white;
  border: 3px solid #505976;
  border-top: none;
  border-bottom: none;
}

.roadmap-circle {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  padding: 0.5rem 0rem;
  background-color: white;
  border: 3px solid #505976;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap-circle > p {
  font-weight: bold;
  font-size: 1.7rem;
  color: #082b9d;
}

.email-input-message {
  color: white;
}

footer {
  background-color: #505976;
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  padding: 2em;
}

.footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 1em; /* Space between the buttons */
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
  margin: 0;
}
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: #505976; /* Footer background color */
}

.footer-buttons {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 1em; /* Space between buttons */
}

.footer-link {
  text-decoration: none;
  color: white;
  padding: 0.5em 1em;
  border: 1px solid white;
  border-radius: 5px; /* Smooth button edges */
  display: inline-block;
  transition: background-color 0.3s, color 0.3s; /* Smooth hover effect */
  cursor: pointer;
}

.footer-link:hover {
  background-color: white;
  color: #505976;
}

@media (max-width: 600px) {
  .footer-buttons {
    flex-direction: column;
    align-items: center;
    gap: 0.5em; /* Reduce space between buttons for smaller screens */
  }
  
  .footer-link {
    width: 100%;
    text-align: center; /* Make buttons fill width and be centered on small screens */
  }
}



@media (max-width: 860px){
  
  .header{
    padding-bottom: 1em;
  }
  
  .intro-video, .search, .share{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-bottom: 2em;
  }

  .profile, .e-commerce{
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    padding-bottom: 2em;
  }
  
  .video{
    margin: 1rem 2em 4rem 2rem;
  }
  
  .ContentSection{
    margin: 3rem 3rem;
    text-align: left;
    font-size: 1em;
  }

  .email{
    font-size: 1rem;
    padding-bottom: 20em;
  }
  
  .email-input{
    width: 100%;
    height: auto;
    display: flex;
    align-content: space-around;
  }

  .email-input input[type="email"]{
    height: 90%;
  }

  .email-input button[type="submit"]{
    width: 40%;
    padding: 1em 0.2em;
    font-size: 0.8rem;    
  }
  
  .intro-content{
    margin: 2rem 3rem;
    text-align: left;
    font-size: 1em;
  }
  
  .intro-content .title{
    margin-bottom: 1em;
  }
  
  .roadmap{
    visibility: hidden;
    flex: 0.5 0.5 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .roadmap-line {
    height: 10%;
    width: 0.1rem;
    background-color: white;
    border: 3px solid #505976;
    border-top: none;
    border-bottom: none;
  }
  
  .roadmap-circle {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    padding: 0.02rem 0rem;
    background-color: white;
    border: 3px solid #505976;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .roadmap-circle > p {
    font-weight: bold;
    font-size: 1rem;
    color: #082b9d;
  }
  
  .ImageSection, .share-image{
    justify-content: center;
    height: 50%;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 0em;
    margin-bottom: 0em;
  }
  
  .image1, .image2, .share-image1{
    height: 80%;
    width: auto;
    border-radius: 1em;
    border: black solid 1.5px;
  }
  
  .profile .image1{
    position: absolute;
    z-index: 2;
    margin: 2em 0em auto 4em;
  }
  
  .profile .image2{
    position: relative;
    z-index: 1;
    margin: auto 8em 0em 4em;
  }
  
  
  .search .image1{
    position: absolute;
    z-index: 2;
    transform: rotate(0deg);
    margin: 1em 2em 0em auto;
  }
  .search .image2{
    position: relative;
    z-index: 1;
    transform: rotate(0deg);
  }
  
  .e-commerce .image1{
    position: absolute;
    z-index: 2;
    transform: rotate(0deg);
    margin: 2em 0em auto 4em;
  }
  .e-commerce .image2{
    position: relative;
    z-index: 1;
    transform: rotate(0deg);
    margin: auto 8em 0em 4em;
  }
  
  .share-image1{
    transform: rotate(0deg);
    margin: auto;
  }
  
}